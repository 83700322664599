import React, { useContext, useMemo, useCallback, useEffect } from 'react'
import { useTable, useSortBy, useFlexLayout, useFilters } from 'react-table'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TableVirtuoso } from 'react-virtuoso'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import Cell from './Cell'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import stringFields from '../../../../utils/constants/widgets/stringFields'
import getHiddenColumns from '../../../../utils/widgets/getHiddenColumns'
import { GlobalStateContext } from '../../../wrappers/GlobalStateContext'

// Define a default UI for filtering
const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
}) => {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            className="input is-small"
            placeholder={`Search ${count} records...`}
        />
    )
}

const aTagRegex = /(<[a|A][^>]*>)/
const aTagEndRegex = /(<\/[a]>)/



//https://react-table-v7-docs.netlify.app/docs/api/useSortBy  ← Official Docs for version 7 of react table

// There are 3 different available sortTypes for the grids: ‘datetime', ‘basic’, 'alphanumeric’

// basic is the generic sort function if a > b then 1 else - 1 which works perfectly for numbers
// datetime is the same as basic but the values are parsed into dates with getTime() to convert the date to miliseconds since epoch
// alphanumeric is a custom function that sorts strings by dividing numbers from letter and from symbols
// Hard to explain with words… check ouit the docs
const parseColumns = (data, valueLabel, sharedKey, widget) => {
    const exampleRow = data[0] // get the first row of the data to extract cols from
    const hiddenColumns = getHiddenColumns(widget)
    
    const columns = Object.keys(exampleRow)
        .filter((col) => col !== 'Value') // remove the value column, we'll add next w/ proper label
        .filter((col) => !hiddenColumns.includes(col))
        .filter((col) => (sharedKey ? col !== sharedKey : true)) // if there is a shared key, we don't want it to display in the grid
        .map((col) => ({
            Header: () => <Cell value={col} type="string" />,
            accessor: col,
            Cell: ({ cell }) => {
                return (
                    <Cell
                        value={cell.value}
                        type={
                            stringFields.includes(col) &&
                            !(cell.value && cell.value.toString().startsWith('<a'))
                                ? 'string'
                                : null
                        }
                    />
                )},
            Filter: DefaultColumnFilter,
            sortType: exampleRow[col] && typeof (exampleRow[col]) === 'string' && exampleRow[col].startsWith('<a') ? (rowA, rowB, columnId, desc) => {
                try {
                    const valueA = rowA.values[columnId]
                    const valueB = rowB.values[columnId]
                    const contentA = valueA.replace(aTagRegex, '').replace(aTagEndRegex, '')
                    const contentB = valueB.replace(aTagRegex, '').replace(aTagEndRegex, '')
                    return contentA.toLowerCase() < contentB.toLowerCase() ? -1 : 1
                } catch {
                    return -1
                }
            } : stringFields.includes(col) ? 'alphanumeric' : 'basic'
        })) // map column name => {Header: <column_name>, accessor: <column_name>} format

    // all values must be in the 'Value' field of the returned data
    if (Object.keys(exampleRow).find((col) => col === 'Value')) {
        const valueColumn = {
            Header: () => (
                <Cell value={valueLabel ? valueLabel : 'Value'} type="string" />
            ),
            accessor: 'Value',
            Cell: ({ cell }) => <Cell value={cell.value} />,
            Filter: DefaultColumnFilter,
        }
        return [...columns, valueColumn]
    } else {
        return columns
    }
}

export default () => {
    const { 
        sharedPageKey,
        sharedPageId, 
        setSharedPageId, 
    } = useContext(CurrentDashboardContext)
    const { widgetData: widget } = useContext(WidgetDataContext)
    const { globalWidgetState, setGlobalWidgetState, } = useContext(GlobalStateContext)
    const data = widget ? widget.Data : null
    const detailKey = widget ? widget.DetailKey : null
    const columns = useMemo(
        () => parseColumns(data, widget.ValueLabel, widget.SharedKey, widget)
    , [data, widget])

    const tableData = useMemo(() => data, [data])
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 175, // width is used for both the flex-basis and flex-grow
            maxWidth: 300, // maxWidth is only used as a limit for resizing
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setFilter,
    } = useTable(
        { columns, data: tableData, defaultColumn },
        useFilters,
        useSortBy,
        useFlexLayout
    )

    useEffect(() => {
        if (globalWidgetState) { //WSW-223 allow widgets to keep there filter state when expanding the widget
            const currentWidgetGlobalStateId = Object.keys(globalWidgetState).find(x => x === String(widget.WidgetId))
            if (currentWidgetGlobalStateId) {
                const currentlyAppliedFilters = globalWidgetState[currentWidgetGlobalStateId].filters
                currentlyAppliedFilters.forEach(({id, value}) => {
                    setFilter(id, value)                    
                })
            }           
        }
    }, [])

    useEffect(() => {
        if (state && widget) {            
            setGlobalWidgetState(prev => ({ ...prev, [widget.WidgetId]: state }))
        }        
    }, [state, widget, setGlobalWidgetState])

    const onClick = useCallback(
        (row) =>
            sharedPageKey &&
            detailKey !== sharedPageKey &&
            setSharedPageId(row.original[sharedPageKey]),
        [sharedPageKey, setSharedPageId, detailKey]
    )

    const components = useMemo(() => {
        return {
            Table: ({ style, ...props }) => (
                <table
                    key={'react-table'}
                    {...getTableProps()}
                    {...props}
                    style={{ ...style }}
                    className="table is-fullwidth is-striped"
                />
            ),
            TableBody: React.forwardRef(({ style, ...props }, ref) => (
                <tbody
                    {...getTableBodyProps()}
                    {...props}
                    ref={ref}
                    className="tbody"
                />
            )),
            TableRow: (props) => {
                const index = props['data-index']
                const row = props.context.rows[index]
                return (
                    <tr
                        {...props}
                        {...row.getRowProps()}
                        onClick={() => onClick(row)}
                        className={`tr ${
                            sharedPageKey &&
                            sharedPageId === row.original[sharedPageKey]
                                ? 'active'
                                : ''
                        } ${sharedPageKey ? 'pointer' : ''}`}
                    />
                )
            },
        }
    }, [sharedPageKey])

    return (
        <div className="gridWrapper">
            <AutoSizer defaultHeight={300} disableWidth>
                {({ height }) => {
                    return (
                        <TableVirtuoso
                            context={{ rows }}
                            className="reactVirtuosoContainer"
                            style={{ height: height }}
                            data={rows}
                            totalCount={rows.length}
                            components={components}
                            fixedHeaderContent={() => {
                                return headerGroups.map(
                                    (headerGroup, index) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                            key={index}
                                            className="tr"
                                        >
                                            {headerGroup.headers.map(
                                                (column, i) => (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        key={i}
                                                    >
                                                        <div className="tableHeaderWrapper">
                                                            <div>
                                                                <span
                                                                    {...column.getSortByToggleProps(
                                                                        {
                                                                            className:
                                                                                'gridColumnHeader sticky th',
                                                                        }
                                                                    )}
                                                                >
                                                                    {column.render(
                                                                        'Header'
                                                                    )}
                                                                    <span className="gridColumnIcon">
                                                                        {column.isSorted ? (
                                                                            column.isSortedDesc ? (
                                                                                <FaChevronUp fontSize="0.75rem" />
                                                                            ) : (
                                                                                <FaChevronDown fontSize="0.75rem" />
                                                                            )
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="tableFilterWrapper">
                                                                {column.canFilter
                                                                    ? column.render(
                                                                          'Filter'
                                                                      )
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    )
                                )
                            }}
                            itemContent={(index) => {
                                const row = rows[index]
                                prepareRow(row)
                                return row.cells.map((cell) => (
                                    <td {...cell.getCellProps()} className="td">
                                        {cell.render('Cell')}
                                    </td>
                                ))
                            }}
                        />
                    )
                }}
            </AutoSizer>
        </div>
    )
}
