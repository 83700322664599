import React, { createContext, useState, } from 'react'

const GlobalStateContext = createContext(null)

const GlobalStateContextProvider = ({ children }) => {
    const [globalWidgetState, setGlobalWidgetState] = useState({})

    return (
        <GlobalStateContext.Provider
            value={{
                globalWidgetState,
                setGlobalWidgetState,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    )
}

export { GlobalStateContext }
export default GlobalStateContextProvider
